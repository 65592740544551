import { defineComponent as _defineComponent } from 'vue'
import MetadataModule from "@/store/modules/Metadata";
import { onMounted } from "vue";
import { useRouter } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'PrivateRoutes',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();

router.afterEach(async (to, from) => {
  if (to.name !== "Login") {
    await MetadataModule.getFacilityByCountryAction();
  }
});
onMounted(async () => {
  await MetadataModule.getFacilityByCountryAction();
});

const __returned__ = { router, get MetadataModule() { return MetadataModule }, onMounted, get useRouter() { return useRouter } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})